import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Table } from 'antd';
import './MinerStats.less';

const { Title } = Typography;

const MinerStats = ({ user, exchange, from }) => {
  const [miners, setMiners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalBlocks, setTotalBlocks] = useState(0);

  const columns = [
    {
      title: 'Miner',
      dataIndex: 'miner',
      key: 'miner',
    },
    {
      title: `Blocks (Total: ${totalBlocks})`,
      dataIndex: 'count',
      key: 'count',
      width: "100px",
    },
    {
      title: 'Market share',
      dataIndex: 'marketShare',
      key: 'marketShare',
    },
  ];

  useEffect(() => {
    getMiners();
  }, []);

  const getMiners = async () => {
    try {
      setLoading(true);

      let response = await axios.get(`/api/tasks/getMiners?exchange=${exchange}&from=${from.valueOf()}`, {
        headers: {
          'x-access-token': user['accessToken'],
        },
      });

      let data = response.data;
      prepareMinersDataForTable(data);
      addMarketShare(data);

      setMiners(data);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const prepareMinersDataForTable = (miners) => {
    miners.sort((a, b) => b.count - a.count).forEach((miner) => (miner.key = miner.miner));
  };

  const addMarketShare = (miners) => {
    const totalCount = miners.reduce((sum, item) => sum + item.count, 0);
    setTotalBlocks(totalCount);

    miners.forEach((miner) => (miner.marketShare = ((miner.count * 100) / totalCount).toFixed(2) + '%'));
  };

  return (
    <Table
      title={() => <Title level={4}>{exchange}</Title>}
      className={'miners-table'}
      dataSource={miners}
      columns={columns}
      size={'small'}
      loading={loading}
      scroll={{ x: 'max-content' }}
      pagination={{ pageSize: 20 }}
    />
  );
};

export default MinerStats;
