import {SearchOutlined} from "@ant-design/icons";
import {Button, DatePicker, Input, message, PageHeader, Space, Table} from "antd";
import moment from "moment";
import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import "./Withdrawals.less";
import Highlighter from "react-highlight-words";
import {networksMapping} from "../../constants/networksMapping";

const dateFormat = "DD.MM.YYYY HH:mm:ss";

const {RangePicker} = DatePicker;
const datePickerFormat = "DD.MM.YYYY";

class Withdrawals extends React.Component {
  mount;
  controller = new AbortController();
  CEX = ["binance", "gateio", "huobipro", "kucoin", "mexc", "whitebit", "bybit", "okx", "bitmart"];
  DEX_NETWORKS = ["eth", "bsc"];

  constructor(props) {
    super(props);

    this.state = {
      datePickerValue: this.getDefaultDateRange(),
      searchedColumn: null,
      showFilter: props.hasOwnProperty("showFilter") ? props.showFilter : true,
      hideColumns: props.hasOwnProperty("hideColumns") ? props.hideColumns : [],
      windowWidth: undefined,
      loading: false,
      final: [],
      addresses: {},
    };

    this.loadTransfers = this.loadTransfers.bind(this);
    this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  onChangeDatePicker(value) {
    value[0] = value[0].startOf("day");
    value[1] = value[1].endOf("day");
    if (this.mount) {
      this.setState({datePickerValue: value});
    }
  }

  refresh() {
    this.loadTransfers();
  }

  getDefaultDateRange() {
    return [
      moment().subtract(3, "days").startOf("day"),
      moment().endOf("day"),
    ];
  }

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset(clearFilters) {
    clearFilters();
    this.setState({searchText: ""});
  };

  componentDidMount() {
    this.mount = true;
    this.loadAddresses();
  }

  componentWillUnmount() {
    this.controller.abort();
    this.mount = false;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.datePickerValue !== this.state.datePickerValue) {
      this.loadTransfers();
    }
  };

  async loadAddresses() {
    const {loading} = this.state;

    if (loading) {
      return null;
    }

    if (this.mount) {
      this.setState({loading: true, error: false});
    } else {
      return;
    }

    try {
      const transfers = await fetch(
        `/api/withdrawals/addresses`, {
          signal: this.controller.signal,
          headers: {
            "x-access-token": this.props.user["accessToken"],
          },
        });
      const data = await transfers.json();

      if (this.mount) {
        await new Promise((resolve) => {
          this.setState({addresses: data, loading: false}, resolve);
        });
      }

      await this.loadTransfers();
    } catch (e) {
      console.log(e);
      if (this.mount) {
        this.setState({error: e.message, loading: false});
      }
      return null;
    }
  }

  async loadTransfers() {
    let {datePickerValue, loading} = this.state;
    const {exchanges} = this.props;
    const data = [];

    if (loading) {
      return null;
    }

    if (this.mount) {
      this.setState({loading: true, error: false});
    } else {
      return;
    }

    try {
      const timeFrom = datePickerValue[0].toDate().getTime();
      const timeTo = datePickerValue[1].toDate().getTime();

      await Promise.all(
        exchanges.map(async ex => {
          if (!this.CEX.includes(ex.exchangeId)) {
            return;
          }

          const transfers = await fetch(
            `/api/transfers/${ex.exchangeId}?type=withdrawals&from=${timeFrom}&to=${timeTo}`, {
              signal: this.controller.signal,
              headers: {
                "x-access-token": this.props.user["accessToken"],
              },
            });
          const resp = await transfers.json();

          if (resp.transfers) {
            for (let i in resp.transfers) {
              let transfer = resp.transfers[i];
              if(ex === 'huobipro' && transfer.timestamp > timeTo) {
                continue;
              }
              const dataEl = this.getDataFromTransfer(transfer, ex.exchangeId);
              data.push(dataEl);
            }
          }
        }),
      );

      await Promise.all(
        this.DEX_NETWORKS.map(async nw => {
          const transfers = await fetch(
            `/api/transfers/${nw}?type=withdrawals&from=${timeFrom}&to=${timeTo}`, {
              signal: this.controller.signal,
              headers: {
                "x-access-token": this.props.user["accessToken"],
              },
            });
          const resp = await transfers.json();

          if (resp.transfers) {
            for (let i in resp.transfers) {
              let transfer = resp.transfers[i];
              if (transfer.addressTo === '0x965df5ff6116c395187e288e5c87fb96cfb8141c'){
                continue;
              }
              const dataEl = this.getDataFromTransfer(transfer, nw);
              data.push(dataEl);
            }
          }
        }),
      );

      data.sort((a, b) => new Date(b.date) - new Date(a.date));
      data.sort((a, b) => {
        if (a.isMapped === false && b.isMapped === true) {
          return -1;
        } else if (a.isMapped === true && b.isMapped === false) {
          return 1;
        }
        return 0;
      });

      if (this.mount) {
        await new Promise((resolve) => {
          this.setState({final: data}, resolve);
        });
      }
    } catch (e) {
      console.log(e);
      if (this.mount) {
        this.setState({error: e.message});
      }
    }

    if (this.mount) {
      this.setState({loading: false});
    }
  }

  getDataFromTransfer(transfer, from) {
    const isMapped = this.checkIsMapped(transfer);
    const timestamp = (transfer.timestamp.toString().length === 10) ?
      (transfer.timestamp * 1000) :
      transfer.timestamp;

    let isDex = false;
    let resultFrom = from;

    if(resultFrom === 'eth' || resultFrom === 'bsc') {
      resultFrom = transfer.from;
      isDex = true;
    }

    return {
      key: transfer.txHash || timestamp,
      hash: transfer.txHash || "",
      network: transfer.network || "",
      currency: transfer.currency || "",
      amount: transfer.amount || 0,
      from: resultFrom,
      addressTo: transfer.addressTo || "",
      fee: transfer.fee?.cost || 0,
      date: timestamp,
      isMapped: isMapped,
      isDex: isDex,
    }
  }

  checkIsMapped(transfer) {
    const {addresses} = this.state;
    const addressesDictionary = addresses.dictionary;

    const network = transfer.network;
    const address = transfer.addressTo.toLowerCase();
    const generalNetwork = networksMapping[network] || "";

    if (addressesDictionary[generalNetwork]) {
      const networkAddresses = addressesDictionary[generalNetwork];
      if (networkAddresses[address]) {
        return true;
      }
    }
    return false;
  }

  getColumnSearchProps(dataIndex) {
    if (!this.state.showFilter) {
      return {};
    }

    return {
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
        <div style={{padding: 8}}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{width: 188, marginBottom: 8, display: "block"}}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({closeDropdown: false});
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: text =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  mapFromForDex(from, network) {
    const {addresses} = this.state;
    const addressesDictionary = addresses.dictionary;
    let result;

    from = from.toLowerCase();

    if (addressesDictionary[network]) {
      const networkAddresses = addressesDictionary[network];
      if (networkAddresses[from]) {
        result = networkAddresses[from];
      }
    }

    if(!result) {
      result =  network;
    }

    return result;
  }

  render() {
    const {
      showFilter,
      hideColumns,
      final,
      windowWidth,
      loading,
      datePickerValue,
      addresses,
    } = this.state;

    const networks = [];
    const currencies = [];
    const fromEx = [];
    const addressesDictionary = addresses.dictionary;

    let transfers = final;
    if (showFilter) {
      for (let i in transfers) {
        let transfer = transfers[i];
        const network = networksMapping[transfer.network] || null;

        if (networks.indexOf(network) < 0) {
          if (network) {
            networks.push(network);
          }
        }

        if (currencies.indexOf(transfer.currency) < 0) {
          currencies.push(transfer.currency);
        }

        if (fromEx.indexOf(transfer.from) < 0) {
          let transferFrom = transfer.from;
          if(transfer.isDex) {
            transferFrom = this.mapFromForDex(transfer.from, transfer.network);
            if(fromEx.indexOf(transferFrom) < 0) {
              fromEx.push(transferFrom);
            }
          } else {
            fromEx.push(transferFrom);
          }
        }
      }
    }

    const columns = [
      {
        title: "Hash",
        dataIndex: "hash",
        key: "hash",
        width: "110px",
        fixed: windowWidth > 800 ? "left" : false,
        render: (hash) => {
          const text = hash ? (`${hash.substr(0, 5)}...${hash.substr(-5)}`) : "";
          return (
            <CopyToClipboard text={hash} onCopy={() => {
              message.success("Copied!");
            }}>
              <a href="#" onClick={(e) => e.preventDefault()}>{text}</a>
            </CopyToClipboard>
          );
        },
      },
      {
        title: "Network",
        dataIndex: "network",
        key: "network",
        width: "60px",
        fixed: windowWidth > 800 ? "left" : false,
        render: (value) => networksMapping[value],
        filters: !!showFilter ? networks.map(nw => {
          return {text: nw, value: nw};
        }) : undefined,
        onFilter: (value, record) => {
          if (networksMapping[record.network]) {
            return networksMapping[record.network].indexOf(value) === 0;
          }
          return false;
        },
      },
      {
        title: "From",
        dataIndex: "from",
        key: "from",
        width: "40px",
        fixed: windowWidth > 800 ? "left" : false,
        render: (value, record) => {
          if(record.isDex) {
            return this.mapFromForDex(value, record.network);
          }
          return value;
        },
        filters: !!showFilter ? fromEx.map(ex => {
          return {text: ex, value: ex};
        }) : undefined,
        onFilter: (value, record) => {
          if(record.isDex) {
            const mappedFrom = this.mapFromForDex(record.from, record.network);
            return mappedFrom.indexOf(value) === 0;
          }
          return record.from.indexOf(value) === 0;
        },
      },
      {
        title: "To",
        dataIndex: "addressTo",
        key: "addressTo",
        width: "70px",
        ...this.getColumnSearchProps("addressTo"),
        render: (value, record) => {
          let result;
          let textColor = "#ff7066";
          const network = record.network;
          const address = record.addressTo.toLowerCase();

          const generalNetwork = networksMapping[network] || "";

          if (addressesDictionary[generalNetwork]) {
            const networkAddresses = addressesDictionary[generalNetwork];
            if (networkAddresses[address]) {
              result = networkAddresses[address];
              textColor = "#389e0d";
            } else {
              result = address;
            }
          } else {
            result = address;
          }
          return <span style={{color: textColor}}>{result}</span>;
        },
      },
      {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
        width: "20px",
        filters: !!showFilter ? currencies.map(curr => {
          return {text: curr, value: curr};
        }) : undefined,
        onFilter: (value, record) => record.currency.indexOf(value) === 0,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: "70px",
        sorter: !!showFilter ? (a, b) => a.amount - b.amount : undefined,
      },
      {
        title: "Fee",
        dataIndex: "fee",
        key: "fee",
        width: "70px",
        sorter: !!showFilter ? (a, b) => a.fee - b.fee : undefined,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "130px",
        fixed: windowWidth > 800 ? "right" : false,
        render: (date) => <>{moment(date).format(dateFormat)}</>,
        sorter: !!showFilter ? (a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime() : undefined,
      },
    ];

    return (
      <>
        <PageHeader
          className="site-page-header"
          backIcon={false}
          title="Withdrawals"
          loading={loading}
          subTitle={
            <Space>
              <Button type={"primary"} className={"refresh-icon"} disabled={loading} onClick={_ => this.refresh()}>
                <img src="/public/imgs/refresh.svg" alt="refresh" />
              </Button>
            </Space>
          }
          extra={[
            <RangePicker
              key={"range-picker"}
              showTime={false}
              disabled={loading}
              format={datePickerFormat}
              defaultValue={datePickerValue}
              onCalendarChange={this.onChangeDatePicker}
            />,
          ]}
        >
          <Table
            key={"transfers-table"}
            className={"transfers-table"}
            columns={columns.filter(col => hideColumns.indexOf(col.dataIndex) < 0)}
            dataSource={final}
            size="middle"
            pagination={{defaultPageSize: 25}}
            loading={loading}
            rowClassName={(row) => "transfer-" + row.key}
            scroll={{x: "max-content"}}
          />
        </PageHeader>
      </>

    );

  }
}

export default Withdrawals;
