import React, { useState, useEffect } from 'react';
import { Typography, DatePicker, Row, Col, Spin } from 'antd';
import { Column } from '@ant-design/charts';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const sortList = ['eth', 'bsc', 'sol', 'tron'];

const checkIcon = '/public/imgs/checkmark.svg';
const dateFormat = 'DD.MM.YYYY';

const GasAndBribeChart = ({ user, from, to, tokenPrices }) => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [totalFeesCost, setTotalFeesCost] = useState(0);
  const [datePickerValue, setDatePickerValue] = useState([
    moment().subtract(30, 'days').startOf('day'),
    moment().endOf('day'),
  ]);

  useEffect(() => {
    if (tokenPrices) {
      getGasAndBribe();
    } else {
      setLoading(true);
    }
  }, [tokenPrices]);

  const getGasAndBribe = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `/api/tasks/getMinerTipsAndGasPrice?from=${datePickerValue[0].valueOf()}&to=${datePickerValue[1].valueOf()}`,
        {
          headers: {
            'x-access-token': user['accessToken'],
          },
        }
      );

      const configData = getDataForColumnConfig(response.data);
      const config = getColumnConfig(configData);

      setConfig(config);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const getDataForColumnConfig = (fees) => {
    let totalInUsdOfAllPeriod = 0;

    for (let fee of fees) {
      if (fee.exchangeId === 'eth') {
        fee.currency = 'ETH';
      } else if (fee.exchangeId === 'bsc') {
        fee.currency = 'BNB';
      } else if (fee.exchangeId === 'sol') {
        fee.currency = 'SOL';
      } else if (fee.exchangeId === 'tron') {
        fee.currency = 'TRX';
      }

      fee.gasFee = fee.networkFee - fee.minerTip;
      fee.total = fee.minerTip + fee.gasFee;
      fee.totalInUsd = fee.total * tokenPrices[fee.currency];
      fee.timestamp = new Date(fee.timestamp).toLocaleDateString();

      totalInUsdOfAllPeriod += fee.totalInUsd;
    }

    const configData = [
      ...fees
        .reduce((r, o) => {
          const key = o.timestamp + '-' + o.exchangeId;

          const item =
            r.get(key) ||
            Object.assign({}, o, {
              gasFee: 0,
              minerTip: 0,
              total: 0,
              totalInUsd: 0,
            });

          item.gasFee += o.gasFee;
          item.minerTip += o.minerTip;
          item.total += o.total;
          item.totalInUsd += o.totalInUsd;

          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    setTotalFeesCost(totalInUsdOfAllPeriod);

    return configData.sort((a, b) => {
      return sortList.indexOf(a.exchangeId) - sortList.indexOf(b.exchangeId);
    });
  };

  const onDatePickerChanged = (value) => {
    setDatePickerValue([value[0].startOf('day'), value[1].endOf('day')]);
  };

  const applyFilters = async () => {
    await getGasAndBribe();
  };

  const getColumnConfig = (configData) => {
    return {
      data: configData,
      xField: 'timestamp',
      yField: 'totalInUsd',
      colorField: 'exchangeId',
      stack: true,
      percent: false,
      tooltip: (item) => {
        return { origin: item };
      },
      interaction: {
        tooltip: {
          render: (e, { title, items }) => {
            return (
              <div>
                <h4>{title}</h4>
                {items.map((item) => {
                  const { name, color, origin } = item;
                  return (
                    <div>
                      <div style={{ margin: 0 }}>
                        <div>
                          <span
                            style={{
                              display: 'inline-block',
                              width: 6,
                              height: 6,
                              borderRadius: '50%',
                              backgroundColor: color,
                              marginRight: 6,
                            }}
                          ></span>
                          <span>{name}</span>
                        </div>
                        <b style={{ display: 'block' }}>{`Gas fee: ${origin['gasFee'].toFixed(6)} ${
                          origin['currency']
                        }`}</b>
                        <b style={{ display: 'block' }}>{`Miner tip: ${origin['minerTip'].toFixed(2)} ${
                          origin['currency']
                        }`}</b>
                        <b style={{ display: 'block', color: '#389e0d' }}>{`Total: ${origin['total'].toFixed(2)} ${
                          origin['currency']
                        } (${origin['totalInUsd'].toFixed(2)} USD)`}</b>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },

      scale: {
        color: {
          range: ['#3689BE', '#FDB269', '#AFDFA9', '#D82428'],
          palette: 'spectral',
        },
      },
    };
  };

  return (
    <>
      <Row>
        <Col xxl={12} sm={24} xs={24}>
          <Title level={4}>Gas prise abd miner tip stats (Total: {totalFeesCost.toFixed(2)} USD)</Title>
        </Col>
        <Col xl={12} sm={24} xs={24} style={{ textAlign: 'end' }}>
          <Spin
            key={'spin'}
            size='small'
            spinning={loading}
            style={{ marginRight: 10 }}
            indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
          />
          <img
            alt='ckechIcon'
            src={checkIcon}
            key='check-icon'
            style={{ width: '30px', height: '30px', cursor: 'pointer', marginRight: '5px' }}
            onClick={applyFilters}
          />
          <RangePicker
            showTime={false}
            disabled={loading}
            format={dateFormat}
            onCalendarChange={onDatePickerChanged}
            value={datePickerValue}
          />
        </Col>
      </Row>

      <Column {...config} />
    </>
  );
};

export default GasAndBribeChart;
