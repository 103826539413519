import { Tabs } from 'antd';
import './MainStatistics.less';
import moment from 'moment';
import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';

import CalculatedData from './components/CalculatedData';

const { TabPane } = Tabs;

const MainStatistics = forwardRef((props, ref) => {
  const [exchangeTypes, setExchangeTypes] = useState();
  const [strategies, setStrategies] = useState();
  const [triggerTypes, setTriggerTypes] = useState();
  const calculatedDataRefs = useRef([]);

  useImperativeHandle(ref, () => ({
    handleFilterByExchangeType(values) {
      setExchangeTypes(values);
    },
    handleFilterByStrategies(values) {
      setStrategies(values);
    },
    handleFilterByTriggerType(values) {
      setTriggerTypes(values);
    },
    applyFilters() {
      for (let calculatedData of calculatedDataRefs.current) {
        calculatedData.applyFilters();
      }
    },
  }));

  const getDateDaysBeforeNow = (days) => {
    return moment().subtract(days, 'days');
  };

  return (
    <div className={'main-statistics'}>
      <Tabs defaultActiveKey='1' type='card' size={'large'} style={{ marginBottom: 0 }}>
        <TabPane tab='Today' key='1'>
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[0] = el)}
            days={moment().startOf('day')}
            user={props.user}
            currency={'ETH'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[1] = el)}
            days={moment().startOf('day')}
            user={props.user}
            currency={'USDT'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          {/* <CalculatedData ref={(el) => (calculatedDataRefs.current[2] = el)} days={moment().startOf("day").add(TIMEZONE_OFFSET, 'hours')} user={props.user} currency={"BNB"} /> */}
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[2] = el)}
            days={moment().startOf('day')}
            user={props.user}
            currency={'SOL'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
        </TabPane>
        <TabPane tab='24 hours' key='2'>
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[3] = el)}
            days={getDateDaysBeforeNow(1)}
            user={props.user}
            currency={'ETH'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[4] = el)}
            days={getDateDaysBeforeNow(1)}
            user={props.user}
            currency={'USDT'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          {/* <CalculatedData ref={(el) => (calculatedDataRefs.current[2] = el)} days={getDateDaysBeforeNow(1)} user={props.user} currency={"BNB"} /> */}
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[5] = el)}
            days={getDateDaysBeforeNow(1)}
            user={props.user}
            currency={'SOL'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
        </TabPane>
        <TabPane tab='This month' key='3'>
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[6] = el)}
            days={moment().startOf('month')}
            user={props.user}
            currency={'ETH'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[7] = el)}
            days={moment().startOf('month')}
            user={props.user}
            currency={'USDT'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          {/* <CalculatedData ref={(el) => (calculatedDataRefs.current[2] = el)} days={moment().startOf("month").add(TIMEZONE_OFFSET, 'hours')} user={props.user} currency={"BNB"} /> */}
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[8] = el)}
            days={moment().startOf('month')}
            user={props.user}
            currency={'SOL'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
        </TabPane>
        <TabPane tab='30 days' key='4'>
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[9] = el)}
            days={getDateDaysBeforeNow(30)}
            user={props.user}
            currency={'ETH'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[10] = el)}
            days={getDateDaysBeforeNow(30)}
            user={props.user}
            currency={'USDT'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
          {/* <CalculatedData ref={(el) => (calculatedDataRefs.current[2] = el)} days={getDateDaysBeforeNow(30)} user={props.user} currency={"BNB"} /> */}
          <CalculatedData
            ref={(el) => (calculatedDataRefs.current[11] = el)}
            days={getDateDaysBeforeNow(30)}
            user={props.user}
            currency={'SOL'}
            exchangeTypes={exchangeTypes}
            strategies={strategies}
            triggerTypes={triggerTypes}
          />
        </TabPane>
      </Tabs>
    </div>
  );
});

export default MainStatistics;
