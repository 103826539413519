import { Col, PageHeader, Row, Typography, Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import BalancesCard from './components/BalancesCard';
import MainStatistics from './components/MainStatistics';
import ShortTaskList from './components/ShortTaskList';
import TasksDailyChart from './components/TasksDailyChart';
import axios from 'axios';
import './Dashboard.less';

const { Title } = Typography;
const checkIcon = '/public/imgs/checkmark.svg';
const triggerTypes = [
  'cexMarketUpdate',
  'dexReservesUpdate',
  'mempool',
  'kolibrio',
  'rpcFast',
  'mevBlocker',
  'flashbots',
  'cexMarketUpdateWs',
  'cexMarketUpdateHttp',
  'blink',
  'merkle'
];

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [dailyChartLoading, setDailyChartLoading] = useState(false);
  const [exchangesTypes, setExchangesTypes] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const tasksDailyChartRef = useRef();
  const mainStatisticsRef = useRef();

  useEffect(() => {
    getExchangesAndStrategies();
  }, []);

  const getExchangesAndStrategies = async () => {
    try {
      setLoading(true);

      const response = await axios.get('/api/tasks/exchangesAndStrategies', {
        headers: {
          'x-access-token': props.user['accessToken'],
        },
      });

      setExchangesTypes(response.data.exchangesTypes);
      setStrategies(
        response.data.strategies.map((strategy) => {
          if (strategy) 
            return strategy;
        })
      );
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = async () => {
    try {
      setLoading(true);
      await mainStatisticsRef.current.applyFilters();
      await tasksDailyChartRef.current.applyFilters();
    } catch (ex) {
      console.error(ex.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterByExchangeType = (values) => {
    mainStatisticsRef.current.handleFilterByExchangeType(values);
    tasksDailyChartRef.current.handleFilterByExchangeType(values);
  };

  const handleFilterByStrategies = (values) => {
    mainStatisticsRef.current.handleFilterByStrategies(values);
    tasksDailyChartRef.current.handleFilterByStrategies(values);
  };

  const handleFilterByTriggerType = (values) => {
    mainStatisticsRef.current.handleFilterByTriggerType(values);
    tasksDailyChartRef.current.handleFilterByTriggerType(values);
  };

  const onDailyChartLoadingChange = (value) => {
    setDailyChartLoading(value);
  };

  return (
    <div className={'dashboard'}>
      <PageHeader
        className={'main-page-content'}
        title={<Title>Dashboard</Title>}
        extra={[
          <Spin
            key={'spin'}
            size='small'
            spinning={loading || dailyChartLoading}
            style={{ marginRight: 10 }}
            indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
          />,
          <img
            alt='ckechIcon'
            src={checkIcon}
            key='check-icon'
            onClick={applyFilters}
            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
          />,
          <Select
            key={'filter-by-exchangetype'}
            className={'filter-by-exchangetype'}
            mode='multiple'
            allowClear
            placeholder='Filter by exchangeId'
            defaultValue={[]}
            disabled={loading || dailyChartLoading}
            onChange={handleFilterByExchangeType}
            maxTagCount={'responsive'}
            options={exchangesTypes.map((type) => {
              return { value: type, label: type };
            })}
          />,
          <Select
            key={'filter-by-strategy'}
            className={'filter-by-strategy'}
            mode='multiple'
            allowClear
            placeholder='Filter by strategy'
            defaultValue={[]}
            disabled={loading || dailyChartLoading}
            onChange={handleFilterByStrategies}
            maxTagCount={'responsive'}
            options={strategies.map((type) => {
              return { value: type, label: type };
            })}
          />,
          <Select
            key={'filter-by-triggertype'}
            className={'filter-by-triggertype'}
            mode='multiple'
            allowClear
            placeholder='Filter by triggerType'
            defaultValue={[]}
            disabled={loading || dailyChartLoading}
            onChange={handleFilterByTriggerType}
            maxTagCount={'responsive'}
            options={triggerTypes.map((type) => {
              return { value: type, label: type };
            })}
          />,
        ]}
      >
        <div className='site-card-border-less-wrapper'>
          <Row gutter={5}>
            <Col span={24} order={2} xl={{ span: 14, order: 1 }}>
              <MainStatistics ref={mainStatisticsRef} user={props.user} />
            </Col>
            <Col span={24} order={1} xl={{ span: 10, order: 2 }}>
              {props.exchanges.length > 0 && <BalancesCard user={props.user} exchanges={props.exchanges || []} />}
            </Col>
          </Row>
          <TasksDailyChart ref={tasksDailyChartRef} user={props.user} onLoadingChange={onDailyChartLoadingChange} />
          <ShortTaskList user={props.user} exchanges={props.exchanges} />
        </div>
      </PageHeader>
    </div>
  );
};

export default withRouter(Dashboard);
