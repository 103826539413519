import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Badge } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './Nav.less';

const pages = [
  {
    title: 'Dashboard',
    route: '/',
    subMenu: [],
  },
  {
    title: 'Tasks',
    route: '/tasks',
    subMenu: [],
  },
  {
    title: 'Miners',
    route: '/miners',
    subMenu: [],
  },
  {
    title: 'Volumes',
    route: '/volumes',
    subMenu: [],
  },
  {
    title: 'Withdrawals',
    route: '/withdrawals',
    subMenu: [],
  },
  {
    title: 'Tokens',
    route: '/tokens',
    subMenu: [],
  },
  {
    title: 'Exchanges',
    route: '/exchanges',
    subMenu: [],
  },
  {
    title: 'Config',
    route: '/config',
    subMenu: [],
  },
  {
    title: 'Reports',
    route: '/reports',
    subMenu: [],
  },
];

const Nav = ({ badgeValue, logout, history }) => {
  const getMenuItems = () => {
    const menuItems = pages.map((page, index) => {
      let badgeCount = 0;
      if (page.title === 'Tokens') {
        badgeCount = badgeValue && badgeValue['Tokens'] ? badgeValue['Tokens'] : 0;
      }

      if (page.subMenu.length > 0) {
        return {
          key: String(index + 1),
          label: (
            <span>
              {page.title} <DownOutlined style={{ fontSize: '10px' }} />
            </span>
          ),
          children: page.subMenu.map((subMenuPage, i) => ({
            key: `${index + 1}-${i}`,
            label: <Link to={page.route + subMenuPage.route}>{subMenuPage.title}</Link>,
          })),
        };
      } else {
        return {
          key: String(index + 1),
          label: (
            <Link to={page.route}>
              <Badge count={badgeCount} overflowCount={99}>
                {page.title}
              </Badge>
            </Link>
          ),
        };
      }
    });

    menuItems.push({
      style: { marginLeft: 'auto' },
      key: String(pages.length + 1),
      label: <a onClick={logout}>Log Out</a>,
    });

    return menuItems;
  };

  const getSelectedIndex = () => {
    let find = pages.find((page) => page.route.split('/')[1] === history.location.pathname.split('/')[1]) || pages[0];

    return [String(find ? pages.indexOf(find) + 1 : null)];
  };

  return (
    <div className={'navbar'}>
      <Menu theme='dark' mode='horizontal' selectedKeys={getSelectedIndex()} items={getMenuItems()} />
    </div>
  );
};

export default withRouter(Nav);
