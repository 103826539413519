import React, { useState } from 'react';
import { Input } from 'antd';

const TextArea = ({ id, rows, style, bordered, value, onChange }) => {
  const [localValue, setLocalValue] = useState(value);
  
  const onValueChanged = (event) => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  return (
    <Input.TextArea
      id={id}
      rows={rows}
      style={style}
      bordered={bordered}
      value={localValue}
      onChange={onValueChanged}
    />
  );
};

export default TextArea;
