import React from 'react';
import { Alert, Modal, Form, Input, InputNumber, Select, message, Spin, Typography } from "antd";
const { Paragraph } = Typography;

import "./InterruptTaskModal.less";

const { Option } = Select;

class InterruptTaskModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit() {
        const { taskId } = this.props;
        await this.setState({
            loading: true,
            error: false
        });

        let errorMessage;
        try {
            let response = await fetch(`/api/tasks/${taskId}/interrupt`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if (response.status === 200) {
                message.success('Success');
                await this.setState({
                    loading: false,
                });
                
                this.props.onClose();
                
                return true;
            }

            let responseObj = await response.json();
            errorMessage = responseObj.message;
        } catch (err) {
            errorMessage = err.message;
        }

        await this.setState({
            error: errorMessage,
            loading: false
        });
        return false;
    };

    render() {
        const { taskId, visible, onClose } = this.props;
        const { loading, error } = this.state;

        return (
            <Modal
                className={"task-interrupt-modal"}
                title="Interrupt"
                visible={visible}
                onCancel={onClose}
                onOk={() => this.onSubmit()}
                destroyOnClose
            >
                <Spin spinning={loading}>
                    {
                        !!error &&
                        <Alert type={'error'} message={error} />
                    }
                        <Paragraph>
                            Are you sure about interrupting task {taskId}?
                        </Paragraph>
                
                </Spin>
                
            </Modal>
        )
    }
}

export default InterruptTaskModal;