import React, { useState } from 'react';
import { Switch as AntdSwitch } from 'antd';

const Switch = ({ size, checked, onChange }) => {
  const [localValue, setLocalValue] = useState(checked);
  
  const onValueChanged = (checked) => {
    setLocalValue(checked);
    onChange(checked);
  };

  return (
    <AntdSwitch
      size={size}
      checked={localValue}
      onChange={onValueChanged}
    />
  );
};

export default Switch;
