import React from 'react';
import { withRouter } from 'react-router-dom';
import { PageHeader, Typography, Tabs, Row, Col, Divider } from 'antd';
import MinerStats from './components/MinerStats.jsx';
import moment from 'moment';
import './Miners.less';

const { Title } = Typography;
const { TabPane } = Tabs;

const daysRange = [1, 7, 30];

const Miners = ({ user }) => {
  const getDateDaysBeforeNow = (days) => {
    return moment().subtract(days, 'days');
  };

  return (
    <>
      <PageHeader className={'main-page-content'} title={<Title>Miners</Title>} />

      <Tabs defaultActiveKey={daysRange[0]} type='card' size={'large'} style={{ marginBottom: 0 }}>
        {daysRange.map((days) => {
          return (
            <TabPane tab={`${days} days`} key={days}>
              <Row gutter={40}>
                <Col xxl={8} md={12} sm={24}>
                  <MinerStats user={user} from={getDateDaysBeforeNow(days)} exchange={'eth'} />
                </Col>
                <Divider type="vertical" className='miners-divider miners-first-divider' />
                <Col xxl={8} md={12} sm={24}>
                  <MinerStats user={user} from={getDateDaysBeforeNow(days)} exchange={'bsc'} />
                </Col>
                <Divider type="vertical" className='miners-divider miners-second-divider' />
                <Col xxl={8} md={12} sm={24}>
                  <MinerStats user={user} from={getDateDaysBeforeNow(days)} exchange={'sol'} />
                </Col>
              </Row>
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default withRouter(Miners);
