import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, message, Table } from 'antd';
import ReportStatuses from '../../../constants/reportStatuses';
import moment from 'moment';
import axios from 'axios';

const ReportRequests = forwardRef(({ user, reportType }, ref) => {
  const reportTablePageSize = 10;

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalReports, setTotalReports] = useState(0);
  const [reportsCurrentPage, setReportsCurrentPage] = useState(1);

  const reportColumns = [
    {
      title: 'Download Link',
      dataIndex: 'downloadLink',
      render: (url, data) =>
        data.status === ReportStatuses.Completed && (
          <Button type={'link'} href={url}>
            Download
          </Button>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        let color = 'inherit';
        if (status === ReportStatuses.Error) {
          color = 'red';
        } else if (status === ReportStatuses.Completed) {
          color = 'green';
        } else if (status === ReportStatuses.Pending) {
          color = 'cornflowerblue';
        }

        return (
          <span style={{ color: color }}>
            {Object.keys(ReportStatuses).find((key) => ReportStatuses[key] === status) || 'Unknown'}
          </span>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 200,
      render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Completed At',
      dataIndex: 'updatedAt',
      width: 200,
      render: (date, data) => data.status === ReportStatuses.Completed && moment(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'From Date',
      dataIndex: 'filter',
      width: 200,
      render: (filter) => moment(JSON.parse(filter).fromDate).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'To Date',
      dataIndex: 'filter',
      width: 200,
      render: (filter) => moment(JSON.parse(filter).toDate).format('DD.MM.YYYY HH:mm'),
    },
  ];

  useEffect(() => {
    getReports(1, reportTablePageSize);
  }, []);

  useImperativeHandle(ref, () => ({
    async refreshTable() {
      await getReports();
    },
  }));

  const getReports = async (page, pageSize) => {
    if (!page || !pageSize) {
      (page = 1), (pageSize = reportTablePageSize);
    }
    try {
      setLoading(true);
      setReportsCurrentPage(page);

      let response = await axios.get(
        `/api/tasks/getCsvReports?reportType=${reportType}$page=${page}&size=${pageSize}`,
        {
          headers: {
            'x-access-token': user['accessToken'],
          },
        }
      );

      if (response.status === 200) {
        setReports(response.data.data);
        setTotalReports(response.data.totalReports);
      } else {
        message.error(response.message, 8);
      }
    } catch (ex) {
      message.error(ex.message, 8);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table
      columns={reportColumns}
      rowKey={'id'}
      size={'small'}
      pagination={{
        defaultPageSize: reportTablePageSize,
        total: totalReports,
        current: reportsCurrentPage,
        onChange: (page, pageSize) => {
          getReports(page, pageSize);
        },
      }}
      dataSource={reports}
      loading={loading}
    />
  );
});

export default ReportRequests;
