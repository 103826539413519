export const networksMapping = {
  "eth": "eth",
  "ETH": "eth",
  "ERC20": "eth",
  "ethereum": "eth",
  "Ethereum(ERC20)": "eth",
  "ETH-ERC20": "eth",
  "USDT-ERC20": "eth",

  "bsc": "bsc",
  "BSC": "bsc",
  "BSC_BNB": "bsc",
  "BEP20": "bsc",
  "BEP20(BSC)": "bsc",
  "BNBSmartChain(BEP20)": "bsc",

  "ARBITRUM": "arb",
  "ARB": "arb",
  "ARBI": "arb",
  "Arbitrum": "arb",
  "arbitrum": "arb",
  "ARBEVM": "arb",
  "ARBITRUMONE": "arb",
  "ArbitrumOne(ARB)": "arb",
  "Arbitrum One": "arb",
  "ARBONE": "arb",

  "POL": "pol",
  "MATIC": "pol",
  "POlYGON": "pol",
  "POLYGON": "pol",
  "polygon": "pol",
  "Polygon(MATIC)": "pol",
  "Polygon": "pol",

  "AVA": "ava",
  "AVAXC": "ava",
  "AVAX-C": "ava",
  "CAVAX": "ava",
  "avacchain": "ava",
  "C": "ava",
  "AVAXCCHAIN": "ava",
  "AVALANCHE": "ava",
  "CCHAIN": "ava",
  "AVAX C-Chain": "ava",
  "AvalancheCChain(AVAXCCHAIN)": "ava",
  "Avalanche C": "ava",

  "OPT": "opt",
  "OPTIMISM": "opt",
  "OP": "opt",
  "optimism": "opt",
  "OPETH": "opt",
  "Optimism(OP)": "opt",
  "Optimism": "opt",

  "AMB": "amb",

  "SOL": "sol",
  "Solana(SOL)": "sol",
  "Solana": "sol",
  "SOL-Solana": "sol",
  "SOLANA": "sol",
  "SPL": "sol",

  "AUR": "aur",
  "AURORA": "aur",
  "AURORAEVM": "aur",
  "aurora": "aur",

  "VET": "vet",
  "VeChain(VET)": "vet",

  "TRC20": "tron",
  "Tron(TRC20)": "tron",
  "USDT-TRC20": "tron",
  "TRX": "tron",

  "MERLIN": "merl",

  "BASE": "base",

  "CELESTIA": "celestia"
};
