import React from 'react';
import { Table, Row, Col } from "antd";
import axios from 'axios';
import "./CalculatedData.less";

const ERROR_TYPES_TO_IGNORE = ['ourTransactionMined']

class CalculatedData extends React.Component {
    mount;
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            tokens: {},
            rebalances: [],
            dateTo: props.days,
            loading: false,
            error: false,
        }
    }

    componentDidMount() {
        this.mount = true;
        this.loadStatistics();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currency !== this.props.currency) {
            this.loadStatistics()
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    async applyFilters() {
        await this.loadStatistics();
      }

    async loadStatistics() {
        if (this.state.loading === true) {
            return false;
        }

        this.setState({ loading: true });

        const [tasks, rebalances] = await Promise.all([
            this.getTasks(),
            this.getRebalances()
        ])

        const tokens = this.getTokenStatisticsPerDays(tasks);

        if (this.mount) {
            this.setState({tasks, rebalances, tokens, loading: false});
        }
    }

    async getTasks() {
        let { exchangeTypes, strategies, triggerTypes } = this.props
        try {
            let response = await axios(`/api/tasks?currency=${this.props.currency}&from=` + this.state.dateTo.valueOf() + 
            `&exchangesFilter=${exchangeTypes || ''}` + 
            `&strategies=${strategies || ''}` + 
            `&triggerType=${triggerTypes || ''}`, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if (response.status !== 200) {
                throw new Error('Failed to get tasks');
            }
            return response.data
              .filter(task => (
                task && 
                ['success', 'error'].includes(task.status) && 
                !task.isFailedMemPoolTask
              ));
        } catch (e) {
            console.log(e);
        }

        return [];
    }

    async getRebalances() {
        try {
            const fromDate = this.props.days;
            let response = await axios(`/api/rebalances?currency=${this.props.currency}&from=` + fromDate, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if (response.status !== 200) {
                throw new Error('Failed to get rebalances');
            }

            return response.data;
        } catch (e) {
            console.log(e);
        }

        return [];
    }

    getTaskStatisticsPerDays(tasks) {
        try {
            const statistics = {
                total: 0,
                error: 0,
                profitable: 0,
                success: 0,
                profit: 0,
                rebalanceFee: 0,
            };

            tasks.forEach(task => {
                if (!ERROR_TYPES_TO_IGNORE.includes(task.errorType)) {
                    statistics.total++;
                    
                    if (task.status === 'success') {
                        statistics.success++;
                    } else {
                        statistics.error++;
                    }
                }

                statistics.profit += task.realProfit;
                if (task.realProfit > 0) {
                    statistics.profitable++;
                }
            });

            statistics.rebalanceFee = this.state.rebalances.reduce((sum, tx) => { sum += tx.fee; return sum; }, 0);
            statistics.profit -= statistics.rebalanceFee;

            return statistics;
        } catch (e) {
            console.log(e);
        }

        return {};
    }

    getTokenStatisticsPerDays(tasks) {
        const tokens = {};

        tasks
          .forEach(task => {
              if (!tokens[task.tokenSymbol]) {
                  tokens[task.tokenSymbol] = {
                      profit: 0,
                      tasks: 0,
                      success: 0,
                      error: 0,
                      volume: 0,
                  };
              }
              const token = tokens[task.tokenSymbol];

              token.tasks++;
              token.profit += task.realProfit;
              if (task.status === 'success') {
                  token.success++;
              }
              if (task.status === 'error') {
                  token.error++;
              }
              token.volume += Number(task.statVolume);
          });

        return tokens;
    }

    render() {
        let { tasks, tokens, loading } = this.state;
        const { currency } = this.props;
        const statistics = this.getTaskStatisticsPerDays(tasks);

        let profit = statistics.profit ? +statistics.profit.toFixed(4) : 0;
        let tasksTotal = statistics.total || 0;
        let tasksSuccessful = statistics.success || 0;
        let tasksSuccessfulPercent = (statistics.success * 100 / tasksTotal) || 0;
        let tasksProfitable = statistics.profitable || 0;
        let tasksProfitablePercent = (statistics.profitable * 100 / tasksTotal) || 0;
        let tasksFailed = statistics.error || 0;
        let tasksFailedPercent = (statistics.error * 100 / tasksTotal) || 0;
        let totalVolume = 0;

        const tokensArray = [];

        Object.keys(tokens).forEach(symbol => {
            totalVolume += tokens[symbol].volume;

            let profit = +tokens[symbol].profit
            profit = profit.toFixed(2) == 0 ? profit : profit.toFixed(2);

            tokensArray.push({
                key: symbol,
                symbol: symbol,
                profit: profit,
                volume: +tokens[symbol].volume.toFixed(2),
                tasks: tokens[symbol].tasks
            });
        });

        tokensArray.sort((a, b) => Number(a.profit) > Number(b.profit) ? 1 : -1);
        const topLosersSource = [].concat(tokensArray.filter(val => val.profit < 0)?.filter((value, index) => index < 5));

        tokensArray.sort((a, b) => Number(a.profit) < Number(b.profit) ? 1 : -1);
        const topPerformersSource = [].concat(tokensArray.filter(val => val.profit > 0)?.filter((value, index) => index < 5));

        const coinSource = `/public/imgs/${currency.toLowerCase()}.svg`
        const precision = currency === "ETH" ? 4 : 2;

        const calculationDataColumns = [
            {
                title: 'Property',
                dataIndex: 'property',
                key: 'property',
                width: "120px"
            },
            {
                title: 'Icon',
                dataIndex: 'icon',
                key: 'icon',
                width: "20px"
            },
            {
                title: 'Volume',
                dataIndex: 'volume',
                key: 'volume'
            },
            {
                title: 'Percent',
                dataIndex: 'percent',
                key: 'percent',
                render: (p) => <div className={"percent"}>{p}</div>
            }
        ];

        const topTokensColumns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: "75px"
            },
            {
                title: <>Volume <img style={{ opacity: "1" }} className={"coin-icon"} src={coinSource} alt={currency} /></>,
                dataIndex: 'volume',
                key: 'volume',
                width: "90px"
            },
            {
                title: 'Tasks',
                dataIndex: 'tasks',
                key: 'tasks',
                width: "70px"
            },
            {
                title: <>Profit <img style={{ opacity: "1" }} className={"coin-icon"} src={coinSource} alt={currency} /></>,
                dataIndex: 'profit',
                key: 'profit',
                width: "80px"
            },
        ];

        const calculationDataSource = [
            {
                key: '1',
                property: <b style={{ fontSize: "18px" }}>Total profit</b>,
                icon: <img className={"coin-icon"} src={coinSource} alt={currency} style={{ width: "15px", height: "15px" }} />,
                volume: <b style={{ fontSize: "18px" }}>{profit.toFixed(precision)}</b>,
            },
            {
                key: '2',
                property: <b>Volume</b>,
                icon: <img className={"coin-icon"} src={coinSource} alt={currency} style={{ width: "15px", height: "15px" }} />,
                volume: <b>{+totalVolume.toFixed(2)}</b>,
            },
            {
                key: 'rebalance',
                property: <><b>Rebalance fee</b></>,
                icon: <img className={"coin-icon"} src={coinSource} alt={currency} style={{ width: "15px", height: "15px" }} />,
                volume: <b>{+(statistics.rebalanceFee || 0).toFixed(4)}</b>,
            },
            {
                key: '3',
                property: <b>Tasks</b>,
                volume: <b>{tasksTotal}</b>,
            },
            {
                key: '4',
                property: '- successful',
                volume: <div className={"success-color"}>{tasksSuccessful}</div>,
                percent: <>{tasksSuccessfulPercent.toFixed()}%</>
            },
            {
                key: '5',
                property: '- profitable',
                volume: <div className={"profit-color"}>{tasksProfitable}</div>,
                percent: <>{tasksProfitablePercent.toFixed()}%</>
            },
            {
                key: '6',
                property: '- failed',
                volume: <div className={"fail-color"}>{tasksFailed}</div>,
                percent: <>{tasksFailedPercent.toFixed()}%</>
            }
        ]

        return (
          <div className={"calculation-data"}>
              <Row gutter={10}>
                  <Col span={24} md={{ span: 8 }}>
                      <Table loading={loading} className={"calculation-data-table"} size={"small"} dataSource={calculationDataSource} columns={calculationDataColumns} showHeader={false} bordered={false} pagination={false} />
                  </Col>
                  <Col span={24} md={{ span: 8 }}>
                      <h3>Top performers</h3>
                      <Table loading={loading} className={"top-performers-table"} size={"small"} dataSource={topPerformersSource} columns={topTokensColumns} bordered={false} pagination={false} />
                  </Col>
                  <Col span={24} md={{ span: 8 }}>
                      <h3>Top losers</h3>
                      <Table loading={loading} className={"top-losers-table"} size={"small"} dataSource={topLosersSource} columns={topTokensColumns} bordered={false} pagination={false} />
                  </Col>
              </Row>
          </div>
        )
    }
}

export default CalculatedData;
