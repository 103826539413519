import React from 'react';
import ReactDOM from 'react-dom';
import { Typography, Row, Col, Button } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './Info.less'

import EditTaskModal from './components/EditTaskModal';
import InterruptTaskModal from './components/InterruptTaskModal';
import VolumeEstimationsDrawer from './components/VolumeEstimationsDrawer';
import { getBlockChainExplorerLink, getChainUrlQueryByType } from "../../../../../../utils/utils";
import BlockChainUrlTypes from "../../../../../../constants/blockChainUrlTypes";

const { Title } = Typography;

class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showVE: false,
            showEdit: false,
            showInterrupt: false,
            editErrorMessage: null,
        }
        this.showDrawer = this.showDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    showDrawer() {
        const _this = this;
        _this.setState({
            showVE: true,
        });
        setTimeout(() => {
            ReactDOM.findDOMNode(document.getElementsByClassName('task-' + _this.props.task.taskId)[0]).scrollIntoView();
        }, 1)
    };

    closeDrawer() {
        this.setState({
            showVE: false,
        });
    };

    showModal(modalName) {
        this.setState(this.getModalState(modalName, true));
    };

    closeModal(modalName) {
        this.setState(this.getModalState(modalName, false));
    };

    getModalState(modalName, modalState) {
        if(modalName === "edit") {
            return {
                showEdit: modalState,
            };
        } 
        
        if(modalName === "interrupt") {
            return {
                showInterrupt: modalState,
            };
        }

        return {};
    }

    callbackModal() {
        this.props.updateCardInfo()
    };

    render() {
        let { task, cardRef } = this.props;
        let { showVE, showEdit, showInterrupt } = this.state;

        if (!task || !task.taskId) {
            return <></>
        }

        let volumeEstimations = [];
        try {
            volumeEstimations = JSON.parse(task.volumeEstimations[0].data);
        } catch (e) { }

        const blockExplorerLink = getBlockChainExplorerLink(task.exchangeOperations[0].exchangeId);

        const gasUsed = Number(task?.executedExchangeOperations?.[0]?.gasUsed || 0);
        const minerTip = Number(task?.executedExchangeOperations?.[0]?.minerTip || 0);
        const gasEfficiency = gasUsed === 0 ? 0 : Number(minerTip / gasUsed * Math.pow(10, 9)).toFixed(2).replace(/e-\d+/g, '');

        const competitorGasUsed = Number(task?.taskCompetitor?.competitorGasUsed || 0);
        const competitorMinerTip = Number(task?.taskCompetitor?.competitorMinerTip || 0);
        const competitorGasEfficiency = competitorGasUsed === 0 ? 0 : Number(competitorMinerTip / competitorGasUsed * Math.pow(10, 9)).toFixed(2).replace(/e-\d+/g, '');
        
        return (
            <div className={"info"}>
                <div className={"task-card-section-title"}>
                    {(task.status === 'inProgress' || task.status === 'terminated') && <Button type={"danger"} size={"small"} onClick={() => this.showModal("interrupt")}>
                        <CloseCircleOutlined /> Interrupt
                    </Button>}
                    <Button type="text" size={"small"} onClick={() => this.showModal("edit")}>
                        <EditOutlined /> Edit
                    </Button>
                    
                    <Title level={5}>Info</Title>
                </div>
                {
                    !!task &&
                    <div>
                        <Row gutter={24}>
                            <Col span={8}>
                                <table>
                                    <tbody>
                                        <tr><td colSpan={2}><b>{task.taskId}</b></td></tr>
                                        <tr><td>Gas used</td><td>{gasUsed.toLocaleString()} Gas</td></tr>
                                        <tr><td>Volume</td><td>{Number(task?.volume || 0)}</td></tr>
                                        <tr><td>Miner tip</td><td>{minerTip.toFixed(5)}</td></tr>
                                        <tr><td>Gas Efficiency</td><td>{gasEfficiency}</td></tr>
                                        {!!task?.mempoolTransaction?.txHash && <tr><td>Mempool tx hash</td><td>
                                            <a href={`${blockExplorerLink}${getChainUrlQueryByType(
                                                task.exchangeOperations[0].exchangeId,
                                                BlockChainUrlTypes.Tx,
                                                task.mempoolTransaction?.txHash
                                            )}`} target="_blank">
                                                {task.mempoolTransaction?.txHash.substr(0, 8)}...{task.mempoolTransaction?.txHash.substr(-8)}
                                            </a>
                                        </td></tr>}
                                    </tbody>
                                </table>
                            </Col>
                            <Col span={8}>
                                <table>
                                    <tbody>
                                        <tr><td colSpan={2}><b>Competitor Data</b></td></tr>
                                        {!!task.taskCompetitor && (
                                            <>
                                                <tr><td>Gas used</td><td>{competitorGasUsed.toLocaleString()} Gas</td></tr>
                                                <tr><td>Volume</td><td>{Number(task.taskCompetitor?.competitorVolume).toFixed(5)}</td></tr>
                                                <tr><td>Miner tip</td><td>{competitorMinerTip.toFixed(5)}</td></tr>
                                                <tr><td>Gas Efficiency</td><td>{competitorGasEfficiency}</td></tr>
                                                {!!task.taskCompetitor?.competitorTxHash && <tr><td>Tx hash</td><td>
                                                    <a href={`${blockExplorerLink}${getChainUrlQueryByType(
                                                        task.exchangeOperations[0].exchangeId,
                                                        BlockChainUrlTypes.Tx,
                                                        task.taskCompetitor.competitorTxHash
                                                    )}` } target="_blank">
                                                        {task.taskCompetitor.competitorTxHash.substr(0, 8)}...{task.taskCompetitor.competitorTxHash.substr(-8)}
                                                    </a>
                                                </td></tr>}
                                                {!!task.taskCompetitor?.competitorAddressFrom && <tr><td>Address</td><td>
                                                    <a href={`${blockExplorerLink}${getChainUrlQueryByType(
                                                        task.exchangeOperations[0].exchangeId,
                                                        BlockChainUrlTypes.Address,
                                                        task.taskCompetitor.competitorAddressFrom
                                                    )}`} target="_blank">
                                                        {task.taskCompetitor.competitorAddressFrom.substr(0, 8)}...{task.taskCompetitor.competitorAddressFrom.substr(-8)}
                                                    </a>
                                                </td></tr>}
                                                <tr><td>Arbitrage type</td><td>{task.taskCompetitor?.arbitrageType}</td></tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                            <Col span={8}>
                                <table>
                                    <tbody>
                                        <tr><td colSpan={2}><b>Indicators</b></td></tr>
                                        <tr><td>Is price fell</td><td>{task.isPriceFell ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</td></tr>
                                        <tr><td>Was sell price too low</td><td>{task.wasSellPriceTooLow ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</td></tr>
                                        <tr><td>Are markets calm</td><td>{task.areMarketsCalm ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</td></tr>
                                        <tr><td>Is profit huge</td><td>{task.isProfitHuge ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</td></tr>
                                        <tr><td>Has unsafe markets</td><td>{task.hastUnsafeMarkets ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</td></tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <div>
                            <a href="#" onClick={this.showDrawer}>Show volume estimations</a>
                        </div>

                        <VolumeEstimationsDrawer
                            volumeEstimations={volumeEstimations}
                            tradingAmountFrom={task.volume}
                            visible={showVE}
                            onClose={this.closeDrawer}
                            contRef={cardRef}

                        />

                        <EditTaskModal
                            taskId={task.id}
                            visible={showEdit}
                            onClose={() => this.closeModal("edit")}
                            user={this.props.user}
                            onCallback={this.callbackModal}
                        />
                        <InterruptTaskModal
                            taskId={task.taskId}
                            visible={showInterrupt}
                            onClose={() => this.closeModal("interrupt")}
                            user={this.props.user}
                        />
                    </div>
                }
                {
                    !task &&
                    <div>No Data</div>
                }
            </div>
        )
    }
}

export default Info;
