import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { PageHeader, Typography, Tabs, Row, Col } from 'antd';
import ReportTypes from '../../constants/reportTypes';
import { ReportRequests, ReportForm } from './shared';

const { Title } = Typography;

const Reports = ({ user }) => {
  const [activeTabKey, setActiveTabKey] = useState(ReportTypes.General);
  const reportRequestsRefs = useRef([]);

  const tabs = [
    {
      label: 'General',
      key: ReportTypes.General,
      children: (
        <ReportRequests
          user={user}
          reportType={ReportTypes.General}
          ref={(el) => (reportRequestsRefs.current[ReportTypes.General] = el)}
        />
      ),
    },
    {
      label: 'Withdrawals',
      key: ReportTypes.Withdrawals,
      children: (
        <ReportRequests
          user={user}
          reportType={ReportTypes.Withdrawals}
          ref={(el) => (reportRequestsRefs.current[ReportTypes.Withdrawals] = el)}
        />
      ),
    },
  ];

  const onTabChanged = (activeKey) => {
    setActiveTabKey(activeKey);
  };

  const onReportCreated = async () => {
    await reportRequestsRefs.current[activeTabKey].refreshTable();
  };

  return (
    <>
      <PageHeader className={'main-page-content'} title={<Title>Reports</Title>} />
      <Row gutter={40}>
        <Col xxl={6} xl={9} lg={8} md={8} sm={24}>
          <ReportForm user={user} reportType={activeTabKey} onReportCreated={onReportCreated} />
        </Col>
        <Col flex='auto'>
          <Tabs
            defaultActiveKey={ReportTypes.General}
            activeKey={activeTabKey}
            onChange={onTabChanged}
            type='card'
            size={'large'}
            style={{ marginBottom: 0 }}
            items={tabs}
          />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Reports);
