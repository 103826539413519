import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { Pie } from '@ant-design/charts';
import axios from 'axios';

const { Title } = Typography;

const FeeChart = ({ user, from, tokenPrices }) => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [totalFeesCost, setTotalFeesCost] = useState(0);

  useEffect(() => {
    if (tokenPrices) getCexFees();
    else setLoading(true);
  }, [tokenPrices]);

  const getCexFees = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`/api/tasks/getCexFees?from=${from.valueOf()}`, {
        headers: {
          'x-access-token': user['accessToken'],
        },
      });

      const configData = getDataForPieConfig(response.data);
      const config = getPieConfig(configData);

      setConfig(config);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (currency) => {
    return currency.length > 6 ? `${currency.slice(0, 6)}...` : currency.toUpperCase();
  };

  const getDataForPieConfig = (fees) => {
    for (let fee of fees) {
      const currency = fee.currency.length > 6 ? fee.currency : fee.currency.toUpperCase();
      fee.totalCost *= tokenPrices[currency];
    }

    let totalFees = fees.reduce((sum, fee) => sum + fee.totalCost, 0);
    setTotalFeesCost(totalFees);

    return fees;
  };

  const getPieConfig = (configData) => {
    return {
      data: configData,
      angleField: 'totalCost',
      colorField: 'currency',
      radius: 0.8,
      label: {
        text: (d) => `${formatCurrency(d.currency)}\n${d.totalCost.toFixed(2)} USD`,
        position: 'spider',
        transform: [
          {
            type: 'overlapDodgeY',
          },
        ],
      },
      legend: {
        color: {
          labelFormatter: (text) => formatCurrency(text),
          title: false,
          position: 'left',
          rowPadding: 5,
          itemMarker: 'circle',
        },
      },
      tooltip: {
        title: (d) => formatCurrency(d.currency),
        items: [{ name: 'Total cost', field: 'totalCost', valueFormatter: (d) => d.toFixed(2) + ' USD' }],
      },
      scale: {
        color: {
          palette: 'spectral',
        },
      },
    };
  };

  return (
    <>
      <Title level={4}>Fees (Total: {totalFeesCost.toFixed(2)} USD)</Title>
      {loading ? <p>Loading...</p> : <Pie {...config} />}
    </>
  );
};

export default FeeChart;
