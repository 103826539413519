import React, { useState } from 'react';
import { Button, DatePicker, message, Form, Row, Col } from 'antd';
import { getDateFormat } from '../../TaskList/taskListHelpers';
import moment from 'moment';
import axios from 'axios';
import './ReportForm.less';

const ReportForm = ({ user, reportType, onReportCreated }) => {
  const [csvButtonLoading, setCsvButtonLoading] = useState(false);
  const [datePickerFromValue, setDatePickerFromValue] = useState(moment().subtract(5, 'days'));
  const [datePickerToValue, setDatePickerToValue] = useState(moment());

  const onChangeDatePickerFrom = (value) => {
    setDatePickerFromValue(value);
  };

  const onChangeDatePickerTo = (value) => {
    setDatePickerToValue(value);
  };

  const generateCsvReport = async () => {
    setCsvButtonLoading(true);

    let dateFrom = datePickerFromValue.toJSON();
    let dateTo = datePickerToValue.toJSON();

    try {
      let response = await axios.post(
        `/api/tasks/generateCsvReport`,
        {
          filter: JSON.stringify({ fromDate: dateFrom, toDate: dateTo }),
          reportType: reportType,
        },
        {
          headers: {
            'x-access-token': user['accessToken'],
          },
        }
      );

      if (response.status === 200) {
        message.success('request successfully created', 8);
        onReportCreated();
      } else {
        message.error(response.message, 8);
      }
    } catch (ex) {
      message.error(ex.message, 8);
    } finally {
      setCsvButtonLoading(false);
    }
  };

  return (
    <Form layout={'vertical'}>
      <Row>
        <Col xxl={12} xl={11} sm={24}>
          <Form.Item label={'From Date'}>
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format={getDateFormat()}
              defaultValue={datePickerFromValue}
              disabled={csvButtonLoading}
              onChange={onChangeDatePickerFrom}
              value={datePickerFromValue}
              disabledDate={(date) => date > datePickerToValue}
            />
          </Form.Item>
        </Col>
        <Col flex='auto'>
          <Form.Item label={'To Date'} className={'float-right-xl'}>
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format={getDateFormat()}
              defaultValue={datePickerToValue}
              disabled={csvButtonLoading}
              onChange={onChangeDatePickerTo}
              value={datePickerToValue}
              disabledDate={(date) => datePickerFromValue > date}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          htmlType={'submit'}
          style={{ float: 'right' }}
          type={'primary'}
          loading={csvButtonLoading}
          disabled={csvButtonLoading}
          onClick={generateCsvReport}
        >
          Generate CSV report
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReportForm;
