import React from 'react';
import { Typography, Table } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import "./EthTrxs.less";

import Analytics from './components/Analytics';
import SubmittedTransactions from './components/SubmittedTransactions';
import {getBlockChainExplorerLink, getChainUrlQueryByType} from "../../../../../../utils/utils";
import BlockChainUrlTypes from "../../../../../../constants/blockChainUrlTypes";

const { Title } = Typography;

const columns = [
    {
        title: '',
        dataIndex: 'status',
        width: '25px'
    },
    {
        title: 'TxID',
        dataIndex: 'txid',
    },
    {
        title: 'Out amount',
        dataIndex: 'out_amount',
    },
    {
        title: 'Gas Price',
        dataIndex: 'gas_price',
    },
    {
        title: 'Increase percent',
        dataIndex: 'increase_percent',
    },
    {
        title: 'Network Fee',
        dataIndex: 'network_fee',
    },
    {
        title: 'Block Number',
        dataIndex: 'blockNumber',
    },
    {
        title: 'Mining Time',
        dataIndex: 'mining_time',
        render: (value) => (value + " ms")
    },
    {
        title: 'Comp. txs',
        dataIndex: 'competitors',
        width: '100px'
    },
    {
        title: 'Subm. txs',
        dataIndex: 'submittedTrades',
        width: '100px'
    },
]

class EthTrxs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { analytics, transactions, networkId } = this.props;

        const data = transactions.map((tx, i) => {
            let analizedData = null;
            if (!!analytics) {
                analizedData = analytics.find(a => a.analyzedTxHash.toLowerCase() === tx.txHash.toLowerCase());
            }
            
            return ({
                key: Number(i) + 1,
                status: tx.status === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />,
                txid: <a href={`${getBlockChainExplorerLink(networkId)}${getChainUrlQueryByType(
                    networkId,
                    BlockChainUrlTypes.Tx,
                    tx.txHash
                  )}`} target="_blank">{tx.txHash.substr(0, 8)}...{tx.txHash.substr(-8)}</a>,
                out_amount: tx.currentOutAmount,
                gas_price: tx.GasPrice,
                increase_percent: tx.gasPriceIncreasePercent,
                network_fee: tx.networkFee,
                mining_time: tx.MiningTimeSeconds,
                competitors: tx.ethCompetitorTransactions ? tx.ethCompetitorTransactions.length : 0,
                submittedTrades: tx.ethSubmittedTradeResponses ? tx.ethSubmittedTradeResponses.length : 0,
                blockNumber: tx.blockNumber ? tx.blockNumber : null,
                description: <>
                    <Analytics data={analizedData} />
                    <SubmittedTransactions ethCompetitorTransactions={tx.ethCompetitorTransactions} ethSubmittedTradeResponses={tx.ethSubmittedTradeResponses} analizedData={analizedData} />
                </>
            })
        });

        return (
            <div className={"eth-trxs"}>
                <div className={"task-card-section-title"}>
                    <Title level={5}>DEX transactions</Title>
                </div>
                <Table size="small" pagination={false} columns={columns} dataSource={data} expandedRowRender={record => <div style={{ margin: '10px 0' }}>{record.description}</div>} expandIconColumnIndex={columns.length} />
            </div>
        )
    }
}

export default EthTrxs;
