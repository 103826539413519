import BlockChainUrlTypes from '../constants/blockChainUrlTypes';
import HexAddress from './addressUtil'

export function parseUrl(search) {
  let result = {};
  try {
    let url = search.split('?')[1];
    if (url) {
      let paramSting = url.split('&');
      for (let i in paramSting) {
        let param = paramSting[i].split('=');
        if (param.length === 2) {
          result[param[0]] = param[1];
        }
      }
    }
  } catch (e) {}

  return result;
}

export function getBlockChainExplorerLink(networkId) {
  switch (networkId) {
    case 'eth':
      return 'https://etherscan.io';
    case 'bsc':
      return 'https://bscscan.com';
    case 'aur':
      return 'https://explorer.aurora.dev';
    case 'pol':
      return 'https://polygonscan.com';
    case 'arb':
      return 'https://arbiscan.io';
    case 'base':
      return 'https://basescan.org';
    case 'sol':
      return 'https://solscan.io';
    case 'tron':
      return 'https://tronscan.org';
  }
}

export function getChainUrlQueryByType(networkId, QueryType, QueryValue) {
  let baseQuery = '/';

  if (networkId === 'tron') {
    baseQuery += '#/';
    switch (QueryType) {
      case BlockChainUrlTypes.Block:
        return `${baseQuery}block/${QueryValue}`;
      case BlockChainUrlTypes.Tx:
        return `${baseQuery}transaction/${QueryValue.replace("0x", '')}`;
      case BlockChainUrlTypes.Address:
        return `${baseQuery}address/${HexAddress.toBase58(QueryValue.replace("0x", '41'))}`;
      default:
        return '';
    }
  } else {
    switch (QueryType) {
      case BlockChainUrlTypes.Block:
        return `${baseQuery}block/${QueryValue}`;
      case BlockChainUrlTypes.Tx:
        return `${baseQuery}tx/${QueryValue}`;
      case BlockChainUrlTypes.Address:
        return `${baseQuery}address/${QueryValue}`;
      default:
        return '';
    }
  }
}

export function numberWithCommas(x) {
  return x.toString().replace('.', ',');
}
