import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { Pie } from '@ant-design/charts';
import axios from 'axios';

const { Title } = Typography;

const VolumeChart = ({ user, from, tokenPrices }) => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [totalVolume, setTotalVolume] = useState(0);

  useEffect(() => {
    if (tokenPrices) getVolumes();
    else setLoading(true);
  }, [tokenPrices]);

  const getVolumes = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`/api/tasks/getVolumes?from=${from.valueOf()}`, {
        headers: {
          'x-access-token': user['accessToken'],
        },
      });

      const configData = getDataForPieConfig(response.data);
      const config = getPieConfig(configData);

      setConfig(config);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const getDataForPieConfig = (volumes) => {
    let groupedVolumes = [];

    for (let vol of volumes) {
      let existing = groupedVolumes.find((v) => v.exchangeId === vol.exchangeId);

      if (existing) {
        existing.volume += vol.volume * +tokenPrices[vol.currency];
      } else {
        groupedVolumes.push({ exchangeId: vol.exchangeId, volume: vol.volume * +tokenPrices[vol.currency] });
      }
      groupedVolumes.push();
    }

    let totalVolume = groupedVolumes.reduce((sum, vol) => sum + vol.volume, 0);
    setTotalVolume(totalVolume);

    for (let vol of groupedVolumes) {
      vol.percent = (vol.volume * 100) / totalVolume;
    }

    return groupedVolumes;
  };

  const getPieConfig = (configData) => {
    return {
      data: configData,
      angleField: 'volume',
      colorField: 'exchangeId',
      radius: 0.8,
      limitInPlot: true,
      label: {
        text: (d) => `${d.exchangeId}\n${d.volume.toFixed(2)} USD (${d.percent.toFixed(0) + '%'})`,
        position: 'spider',
        transform: [
          {
            type: 'overlapDodgeY',
          },
        ],
      },
      legend: {
        color: {
          title: false,
          position: 'left',
          rowPadding: 5,
          itemMarker: 'circle',
        },
      },
      tooltip: {
        title: (d) => d.exchangeId,
        items: [
          { name: 'Volume', field: 'volume', valueFormatter: (d) => d.toFixed(2) + ' USD' },
          { name: 'Percent', field: 'percent', valueFormatter: (d) => d.toFixed(0) + '%' },
        ],
      },
      scale: {
        color: {
          palette: 'spectral',
        },
      },
    };
  };

  return (
    <>
      <Title level={4}>Volumes (Total: {totalVolume.toFixed(2)} USD)</Title>
      {loading ? <p>Loading...</p> : <Pie {...config} />}
    </>
  );
};

export default VolumeChart;
